import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { CalendarEvent } from './types/CalendarEvent';
import ChangeEventDate from "./change-event-date";

type Props = {
    event: CalendarEvent
    update: () => void
}

const EventModal = (props: Props) => {
    const [show, setShow] = useState(false);
    const event = new CalendarEvent(props.event);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return <>
        <div className="event shadow-sm"
            onClick={handleShow}
            style={{
                ...event.getPosition()
            }}
            title={event.summary}
        >
            <p>{event.summary}</p>
        </div>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{event.summary}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                <p>{event.description}</p>
                <div className="d-flex" style={{ gap: 10 }}>
                    <i className="bi bi-clock"></i>
                    <p>{event.getDateHoursString('start')} - {event.getDateHoursString('end')}</p>
                </div>
                <label>Участники</label>
                <ul className="list-group mb-3">
                    {
                        event.attendees.map((attendee) => {
                            return <li key={attendee} className="list-group-item">{attendee}</li>
                        })
                    }
                </ul>

                <ChangeEventDate event={event} update={props.update} />

                {/*<ChangeMeetingDateModal*/}
                {/*  step={incompleteItem}*/}
                {/*  candidate={props.candidate}*/}
                {/*  update={update}*/}
                {/*  vacancyId={props.vacancyId}*/}
                {/*/>*/}
            </Modal.Body>
        </Modal>
    </>
}

export default EventModal