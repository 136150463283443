import moment from 'moment';

export class CalendarEvent {
    iCalUID: string
    summary: string
    start: string
    end: string
    description: string
    attendees: string[]
    candidateVacancyStep?: any

    constructor(event: any) {
        this.iCalUID = event.iCalUID
        this.summary = event.summary
        this.start = event.start
        this.end = event.end
        this.description = event.description
        this.attendees = event.attendees
        this.candidateVacancyStep = event.candidateVacancyStep
    }


    getDateHoursString(type: 'start' | 'end') {
        return `${this.getDateString(type)} ${this.getHoursString(type)}`
    }

    getDateString(type: 'start' | 'end') {
        let date = (type === 'start' ? this.start : this.end);
        return moment(date).format('DD.MM.YYYY');
    }

    getHoursString(type: 'start' | 'end') {
        let date = (type === 'start' ? this.start : this.end);
        return moment(date).format('HH:mm');
    }


    public getPosition() {
        const start = moment.utc(this.start).local();
        const end = moment.utc(this.end).local();

        const difM = Math.abs(end.diff(start, 'minutes'));
        const height = Math.abs((difM * 100) / 60); // Высота в %

        const startM = start.minutes();
        const top = 100 * (start.hours() - 7) + (startM === 30 ? height : 0);

        return {
            height,
            top,
        };
    }

}