export const convertUTCToDatestring = (dateString: string) => {
    let date = new Date(dateString)
    let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
    let month = date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1
    let year = date.getFullYear()
    let hour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
    let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
    return `${day}.${month}.${year} \t ${hour}:${minutes}`
}

export const FORMAT_DATE_BASE = "DD.MM.YYYY"