import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import vacancyService from "../../../api/vacancy.api";

type Props = {
    vacancyId: number
    resumeId: number
    update: () => void
}

const DeleteCandidate = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        vacancyService.deleteCandidate(props.vacancyId, props.resumeId).then((response: any) => {
            props.update()
            handleClose()
        })
    }



    return <>
        <a href="/" className='dropdown-item' onClick={(e) => {
            e.preventDefault()
            handleShow()
        }}>
            Удалить
        </a>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление Кандидата из вакансии</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>Вы действительно хотите удалить Кандидата из вакансии?</p>

                <Button onClick={handleDelete} variant='danger'>Удалить</Button>

            </Modal.Body>
        </Modal>
    </>
}

export default DeleteCandidate