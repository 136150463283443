import React, {useEffect, useState} from 'react';
import {Worker, Viewer} from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import axiosInstance from "../api";

interface PdfViewerProps {
    fileId: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({fileId}) => {

    const [pdfData, setPdfData] = useState<Uint8Array | null>(null);
    useEffect(() => {
        const fetchPdf = async () => {
            try {
                const response = await axiosInstance.get(`${fileId}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                });
                const uint8Array = new Uint8Array(response.data);

                setPdfData(uint8Array);
            } catch (error: any) {
                console.error('Ошибка при загрузке PDF:', error.message);
            }
        };

        fetchPdf();
    }, [fileId]);

    return (
        <div className="w-full border border-gray-300">
            <div className="overflow-y-auto h-full">
                {pdfData ? (
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <div className="max-h-[600px] w-[750px] overflow-y-auto">
                            <Viewer fileUrl={pdfData}/>
                        </div>
                    </Worker>
                ) : (
                    <p>Загрузка PDF...</p>
                )}
            </div>
        </div>
    );
};

export default PdfViewer;