import React from 'react'
import {Container} from 'react-bootstrap'
import {useTranslation} from 'react-i18next'
import {useQuery} from "@tanstack/react-query";
import axiosInstance, {API_URL} from "../../../api";

type Props = {}

const fetchDocumentsOffer = async () => {
  const {data} = await axiosInstance.get('/api/offer');
  return data[0];
};

const Footer = (props: Props) => {
  const {t} = useTranslation()

  const {data, error, isLoading} = useQuery({
    queryKey: ['posts'],
    queryFn: fetchDocumentsOffer
  });

  return (
    <footer>
      {data &&
          <Container>
              <p className='text-center'>
                  <a className='fw-bold ms-1' href={`${API_URL}${data.filepath}`} target="_blank">{t("offer-policy-title")}</a>
              </p>
          </Container>
      }

      <Container>
        <p className='text-center'>Copyright ©uki {new Date().getFullYear()} |
          <a className='fw-bold ms-1' href="/docs/policy_of_privacy.pdf" target="_blank">{t("privacy-policy-title")}</a>
        </p>
      </Container>
    </footer>
  )
}

export default Footer