import React, { useEffect, useState } from 'react'
import { DayInfo } from '../../helpers/getCurrentWeek'
import { CalendarEvent } from './types/CalendarEvent'
import EventModal from './EventModal'
import { CalendarDay } from './types/Calendar'
import calendarService from '../../api/calendar.api'

type Props = {
    day: CalendarDay
    rerenderCalendar: any
}



const Day = (props: Props) => {

    const hours = [
        '7:00'
        , '7:30'
        , '8:00'
        , '8:30'
        , '9:00'
        , '9:30'
        , '10:00'
        , '10:30'
        , '11:00'
        , '11:30'
        , '12:00'
        , '12:30'
        , '13:00'
        , '13:30'
        , '14:00'
        , '14:30'
        , '15:00'
        , '15:30'
        , '16:00'
        , '16:30'
        , '17:00'
        , '17:30'
        , '18:00'
        , '18:30'
        , '19:00'
        , '19:30'
        , '20:00'
        , '20:30'
        , '21:00'
        , '21:30'
        , '22:00'
        , '22:30'
        , '23:00'
    ]

    const [events, setEvents] = useState<CalendarEvent[]>([])

    const updateCalendar = () => {
        calendarService.getEvents(props.day.date).then((response: any) => {
            setEvents(response.data)
        })
    }

    useEffect(() => {
        updateCalendar()
    }, [props.day])

    return (
        <div className={`calendar-day-container ${props.day.currentDate ? 'active' : ''}`} >
            <div className="title-block shadow-sm">
                <h5>{props.day.day}</h5>
                <p>{props.day.weekDay}</p>
            </div>
            <div className="calendar-day-content">
                {
                    events.length > 0 && events.map((event) => {
                        return <EventModal update={props.rerenderCalendar} event={event} key={event.iCalUID} />
                    })
                }

            </div>
            {
                hours.map((item) => {
                    return <div key={item} className='data-block'>
                        <p></p>
                    </div>
                })
            }
        </div>
    )
}

export default Day