import React, {useState} from 'react';
import {Button, Modal} from "react-bootstrap";
import {useTranslation} from "react-i18next";
import ResumeViewer from "../../helpers/file-helper";

interface PDFModalProps {
    fileId: string
    isDropDown?: boolean
}

const PdfModal = ({fileId, isDropDown}: PDFModalProps) => {

    const {t} = useTranslation(); // Хук для перевода текста
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleModalClick = (e: React.MouseEvent) => {
        e.stopPropagation(); // Предотвращаем всплытие события клика
    };

    return (
        <>
            {isDropDown
                ? (
                    <button onClick={(e) => {
                        handleShow();
                    }} className={`dropdown-item`}>
                        Просмотр резюме
                    </button>
                )
                : (
                    <Button onClick={handleShow}>
                        Просмотр резюме
                    </Button>
                )
            }

            <Modal
                size="lg"
                onClick={handleModalClick}
                show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Просмотр резюме</Modal.Title> {/* Переводим заголовок "Удаление резюме" */}
                </Modal.Header>
                <Modal.Body>
                    <ResumeViewer fileId={fileId}/>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default PdfModal;