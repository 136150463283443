import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import vacancyService from "../../../api/vacancy.api";
import {IVacancyShort} from "../../../types/vacancy/IVacancyShort";

type Props = {
    item: IVacancyShort,
    update: () => void
}

const DeleteButton = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        vacancyService.delete(`${props.item.id}`).then((response: any) => {
            props.update()
            handleClose()
        })
    }



    return <>
        <a href="/" className='dropdown-item' onClick={(e) => {
            e.preventDefault()
            handleShow()
        }}>
            Удалить
        </a>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление Вакансии {props.item.position}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>Вы действительно хотите удалить вакансию "{props.item.position}"?</p>
                <p>Все связанные данные с вакансией будут удалены</p>

                <Button onClick={handleDelete} variant='danger'>Удалить</Button>

            </Modal.Body>
        </Modal>
    </>
}

export default DeleteButton