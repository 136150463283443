import React, { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import {IApplicationShort} from "../../../types/application/IApplicationShort";
import applicationService from "../../../api/application.api";

type Props = {
    application: IApplicationShort,
    updateList: () => void
}

const DeleteButton = (props: Props) => {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleDelete = () => {
        applicationService.delete(`${props.application.id}`).then((response: any) => {
            props.updateList()
            handleClose()
        })
    }



    return <>
        <a href="/" className='dropdown-item' onClick={(e) => {
            e.preventDefault()
            handleShow()
        }}>
            Удалить
        </a>

        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Удаление Заявки {props.application.position}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p className='text-danger'>Вы действительно хотите удалить заявку "{props.application.position}"?</p>
                <p>Все связанные данные с заявкой будут удалены</p>

                <Button onClick={handleDelete} variant='danger'>Удалить</Button>

            </Modal.Body>
        </Modal>
    </>
}

export default DeleteButton