import React, {ChangeEvent, useEffect, useState} from 'react'
import { ICandidateVacancyStepItem } from '../../types/candidate/ICandidateVacancyStepItem'
import { Button, Modal } from 'react-bootstrap'
import vacansyService from '../../api/vacancy.api'
import { socket } from '../../App'
import { useStores } from '../../stores'
import { ICandidateShort } from '../../types/candidate/ICandidateShort'
import { IDictionaryItem } from '../../types/IDictionaryItem'
import dictionaryService from '../../api/dictionary.api'
import {toast} from "react-toastify";
import userService from "../../api/user.api";
import {IUserShort} from "../../types/user/IUserShort";
import moment from "moment";
import {FORMAT_DATE_BASE} from "../../helpers/convertUTCToDatestring";

type Props = {
    step: ICandidateVacancyStepItem,
    candidate: ICandidateShort,
    vacancyId: number
    update: () => void
}

const ChangeMeetingDateModal = (props: Props) => {
    const [show, setShow] = useState(false);
    const [isDelete, setIsDelete] = useState(false);
    const [usersSelect, setUsersSelect] = useState<IUserShort[]>([]);
    const [userSelected, setUserSelected] = useState<string>('');
    const handleClose = () => setShow(false);
    const handleShow = async () =>  {
        setShow(true);
        try {
            const {data} = await userService.findByRoles(['hiring', 'hrmanager'])
            setUsersSelect(data)
        } catch (e) {
            console.error(e)
        }
        dictionaryService.findAll("meetingType").then(response => {
           setMeetingTypes(response.data)
        }).catch(e => console.log(e))
    }
    const [meetingTypes, setMeetingTypes] = useState<IDictionaryItem[]>([])
    const [meetingTypeId, setMeetingTypeId] = useState<number>(0)
    const { authStore } = useStores()

    const localDate = new Date(props.step.meetingDate);
    const offsetTimezone = localDate.getTimezoneOffset() * 60000;
    const localDateWithOffset = new Date(localDate.getTime() - offsetTimezone);

    const [meetingDate, setMeetingDate] = useState<string>(props.step.meetingDate ?localDateWithOffset.toISOString().slice(0, 16) : "")

    const handleForm = () => {
        const selectedEmail = userSelected || authStore.user?.email
        vacansyService.changeMeetingDate(props.step.id, moment(meetingDate).unix(), meetingTypeId, selectedEmail)
            .then(response => {
                socket.emit('chat message', JSON.stringify({
                    email: authStore.user?.email,
                    action: `Этап "${props.step.step.name}" с ${props.candidate.firstName} ${props.candidate.lastName} ${!props.step.meetingDate ? "назначен" : "перенесен"} на ${meetingDate}`,
                    link: '/vacancy-candidates/' + props.vacancyId,
                    type: 'meeting-date',
                    users: [props.candidate.email, authStore.user?.email]
                }));
                toast.success('Дата и время встречи успешно изменено.');

                props.update();
                handleClose();
            })
            .catch(error => {
                const message = error?.response?.data === 'CONFLICT_EVENT'
                    ? "На указанную дату или время уже запланировано другое событие."
                    : `Не удалось изменить дату встречи. Попробуйте снова. Ошибка: ${error?.response?.data}`

                toast.error(message);
            });
    }

    const deleteEvent = async () => {
        try {
            const res = await vacansyService.deleteMeetingDate(props.step.id, meetingTypeId)
            toast.success(`Собеседование, запланированное на ${moment(props.step.meetingDate).format(FORMAT_DATE_BASE)} удалено`);
            props.update();
            setIsDelete(false);
            handleClose();
        } catch(error: any) {
            const message = error?.response?.data === 'NOT_FOUND'
              ? "Не удалось найти событие"
              : `Не удалось удалить дату встречи. Попробуйте снова. Ошибка: ${error?.response?.data}`

            toast.error(message);
        }
    }

    const handleSelectUser = (e: ChangeEvent<HTMLSelectElement>) => {
        setUserSelected(e.target.value)
    }

    useEffect(() => {
        const selected = usersSelect.find(item => item.id === props.candidate.id)?.email as any

        setUserSelected(selected || '')

    }, [usersSelect]);

    return <>
        <Button variant="primary" onClick={handleShow}>
            {
                !props.step.meetingDate ? "Назначить" : "Перенести"
            }
        </Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Перенос даты</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isDelete
                ? <>
                      <p className='text-danger'>{`Вы действительно хотите удалить Собеседование, запланированное на "${moment(props.step.meetingDate).format(FORMAT_DATE_BASE)}"?`}</p>
                      <div className={'flex gap-4 mt-2'}>
                          <Button onClick={() => setIsDelete(false)}>Отменить</Button>
                          <Button onClick={deleteEvent} variant='danger'>Удалить</Button>
                      </div>
                  </>
                  : <>
                      <p>На какую дату хотите перенести "{props.step.step.name}"?</p>
                      <input type="datetime-local" className="form-control mb-2"
                             value={meetingDate} onChange={(e) => setMeetingDate(e.target.value)}
                      />
                      <select className='form-select mb-3'
                              value={meetingTypeId} onChange={((e) => setMeetingTypeId(parseInt(e.target.value)))}
                      >
                          <option value=''>-Выберите формат-</option>
                          {
                              meetingTypes.map((type) => (
                                <option value={type.id} key={type.id}>{type.name}</option>
                              ))
                          }
                      </select>

                      <select
                        className='form-select mb-3'
                        value={userSelected || ''}
                        onChange={handleSelectUser}
                      >
                          <option value=''>-Выберите пользователя-</option>
                          {usersSelect.map(user => (
                            <option value={user.email} key={user.id}>
                                {`${user.firstName} ${user.secondName}`}
                            </option>
                          ))}
                      </select>
                      <div className={'flex gap-4 justify-between'}>
                          <Button onClick={handleForm}>Сохранить изменения</Button>
                          <Button disabled={!props.step.meetingDate} onClick={() => setIsDelete(true)}>Удалить</Button>
                      </div>
                  </>
                }
            </Modal.Body>
        </Modal>
    </>
}

export default ChangeMeetingDateModal