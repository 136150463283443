import React from 'react';
import {CalendarEvent} from "./types/CalendarEvent";
import ChangeMeetingDateModal from "../modals/ChangeMeetingDateModal";

interface ChangeEventDateProps {
  event: CalendarEvent
  update: () => void
}
const ChangeEventDate = (props: ChangeEventDateProps) => {
  const incompleteItem: any = {
    ...props.event?.candidateVacancyStep
  }

  return (
    <div>
      <ChangeMeetingDateModal
        step={incompleteItem}
        candidate={props.event?.candidateVacancyStep?.resume?.candidate}
        vacancyId={props.event?.candidateVacancyStep?.step?.vacancy?.id}
        update={props.update}
      />
    </div>
  );
};

export default ChangeEventDate;