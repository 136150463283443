import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ROUTES } from '../../../helpers/routeConsts';
import { useTranslation } from 'react-i18next';
import DeleteButton from "../modal/delete-button";
import {IVacancyShort} from "../../../types/vacancy/IVacancyShort";


type Props = {
    item: IVacancyShort;
    update: any;
};

const OperationsList = (props: Props) => {
    const { t } = useTranslation(); // Инициализируем хук для локализации

    return (
        <Dropdown>
            <Dropdown.Toggle variant='light' id='dropdown-basic'>
                <i className='fa-solid fa-ellipsis-vertical'></i>
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <Link to={`${ROUTES.VACANCY_INFO}/${props.item.id}`} className={`dropdown-item`}>
                    {t('operationsList.details')}
                </Link>
                <Link to={`${ROUTES.VACANCY_CANDIDATS}/${props.item.id}`} className={`dropdown-item`}>
                    {t('operationsList.candidates')}
                </Link>
                <Link to={`${ROUTES.VACANCY_STEPS}/${props.item.id}`} className={`dropdown-item`}>
                    {t('operationsList.steps')}
                </Link>
                <DeleteButton update={props.update} item={props.item}  />
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default OperationsList;
