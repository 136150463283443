import axiosInstance from "."
import { ApplicationCreateRequest } from "../types/application/ApplicationCreateRequest"
import { IApplicationGetFilter } from "../types/application/IApplicationGetFilter"

class ApplicationService {
    async findAll(filter:IApplicationGetFilter) {
        const response = await axiosInstance.post('/api/application/findAll',filter)
        return response
    }

    async create(formDate:ApplicationCreateRequest) {
        const response = await axiosInstance.post('/api/application',formDate)
        return response
    }

    async delete(id: any) {
        const response = await axiosInstance.delete('/api/application/' + id)
        return response
    }

    async update(formData:ApplicationCreateRequest, id:number) {
        const response = await axiosInstance.put('/api/application/'+id,formData)
        return response
    }

    async findForEdit(id:number) {
        const response = await axiosInstance.get('/api/application/edit/'+id)
        return response
    }

    async findById(id:number) {
        const response = await axiosInstance.get('/api/application/'+id)
        return response
    }

    async findForVacancy(id:number) {
        const response = await axiosInstance.get('/api/application/forVacancy/'+id)
        return response
    }

    async setStatus(id:number, statusName:string) {
        const response = await axiosInstance.patch('/api/application/'+id+'/'+statusName)
        return response
    }
}

const applicationService = new ApplicationService()
export default applicationService