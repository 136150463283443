import React from 'react';
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const MyDocumentViewer = ({filePath}: any) => {
    const docs = [
        { uri: `https://api.uki-crm.kz${filePath}` },
    ];

    return (
        <div>
            <DocViewer
                className={'h-[600px]'}
                documents={docs}
                pluginRenderers={DocViewerRenderers}
            />
        </div>
    );
};

export default MyDocumentViewer;