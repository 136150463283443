import axiosInstance from "."
import { IVacancyGetFilter } from "../types/vacancy/IVacancyGetFilter"
import { IVacancyStep } from "../types/vacancy/IVacancyStep"
import { VacancyCreateRequest } from "../types/vacancy/VacancyCreateRequest"
import { VacancyStepCreateRequest } from "../types/vacancy/VacancyStepCreateRequest"

class VacancyService {
    async findAll(filter: IVacancyGetFilter) {
        const response = await axiosInstance.post('/api/vacancy/findAll', filter)
        return response
    }

    async findById(id: number) {
        const response = await axiosInstance.get('/api/vacancy/' + id)
        return response
    }

    async findByIdShort(id: number) {
        const response = await axiosInstance.get('/api/vacancy/findOneShort/' + id)
        return response
    }

    async create(formData: VacancyCreateRequest) {
        const response = await axiosInstance.post('/api/vacancy', formData)
        return response
    }

    async delete(id: string) {
        const response = await axiosInstance.delete(`/api/vacancy/` + id)
        return response
    }

    async deleteCandidate(vacancyId: number, resumeId: number) {
        const response = await axiosInstance.delete(`/api/vacancy/` + vacancyId + '/' + resumeId)
        return response
    }

    async setStatus(id: number, status: string) {
        const response = await axiosInstance.patch('/api/vacancy/' + id + '/' + status)
        return response
    }

    async appendResume(vacancyId: number, resumeId: number) {
        const response = await axiosInstance.patch('/api/vacancy/' + vacancyId + '/appendResume/' + resumeId)
        return response
    }

    async createStep(formData: VacancyStepCreateRequest) {
        const response = await axiosInstance.post('/api/vacancy/step', formData)
        return response
    }

    async completeStep(id: number) {
        const response = await axiosInstance.post('/api/vacancy/completeStep/' + id)
        return response
    }

    async failedStep(id: number) {
        const response = await axiosInstance.post('/api/vacancy/failedStep/' + id)
        return response
    }

    async comeStep(id: number) {
        const response = await axiosInstance.post('/api/vacancy/comeStep/' + id)
        return response
    }
    async updateStep(formData: IVacancyStep) {
        const response = await axiosInstance.put('/api/vacancy/step', formData)
        return response
    }

    async deleteStep(id: number) {
        const response = await axiosInstance.delete('/api/vacancy/step/' + id)
        return response
    }

    async setStepIndex(id: number, index: number) {
        const response = await axiosInstance.put('/api/vacancy/step/setStepIndex/' + id + '/' + index)
        return response
    }

    async changeMeetingDate(id: number, date: any, meetingTypeId: number, emailUser?: string) {
        const response = await axiosInstance.patch(
            '/api/vacancy/step/' + id + '/changeMeetingDate' + "/" + meetingTypeId,
            {emailUser, date}
        )
        return response
    }

    async deleteMeetingDate(id: number, meetingTypeId: number) {
        const response = await axiosInstance.delete(
          '/api/vacancy/step/' + id + '/changeMeetingDate' + "/" + meetingTypeId
        )
        return response
    }
    async stepCompletes(id: number) {
        const response = await axiosInstance.get('/api/vacancy/stepCompletes/' + id)
        return response
    }
}

const vacansyService = new VacancyService()
export default vacansyService