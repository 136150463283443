import React, { useEffect, useState } from 'react';
import axiosInstance from "../api";
import PdfViewer from "./pdf-viewer";
import MyDocumentViewer from "../test";

const ResumeViewer = ({ fileId }: any) => {
    const [fileUrl, setFileUrl] = useState(null);
    const [isPdf, setIsPdf] = useState(false);
    const [isDoc, setIsDoc] = useState(false);

    useEffect(() => {
        const fetchResume = async () => {
            try {
                const response = await axiosInstance.get(`${fileId}`, {
                    responseType: 'arraybuffer',
                    headers: {
                        'Content-Type': 'application/pdf',
                    },
                });

                const fileType = fileId.split('.').pop().toLowerCase(); // Определение типа файла по расширению

                // @ts-ignore
                setFileUrl(URL.createObjectURL(new Blob([response.data])));
                setIsPdf(fileType === 'pdf');
                if (fileType === 'doc' || fileType === 'docx') {
                    return setIsDoc(true)
                }
                // if (fileType !== 'pdf') {
                //     const arrayBuffer = response.data;
                //     mammoth.convertToHtml({ arrayBuffer })
                //         .then((result) => {
                //             setHtmlContent(result.value);
                //         })
                //         .catch((err) => {
                //             console.error(err);
                //         });
                // }
            } catch (error) {
                console.error('Error fetching the file:', error);
            }
        };

        fetchResume();
    }, [fileId]);
    console.log(isDoc)
    return (
        <div>
            {isPdf ? (
                <PdfViewer fileId={fileId}/>
            ) : (
                isDoc ? <MyDocumentViewer filePath={fileId} /> : <h3>Документ не поддерживается</h3>
            )}
        </div>
    );
};

export default ResumeViewer;